import React from 'react';
import { useEffect, useState } from 'react';

import { BrowserRouter, Navigate ,Route, Routes, Outlet, useNavigate } from 'react-router-dom'
import URLComponent from './components/URLComponent';
import axios from "axios";
import { checkAuthorization } from "./controllers/Authorization";

// import RegisterForm from "./components/Forms/Register/Register"
import LoginForm from "./components/Forms/Login/Login"
// import RegSuccess from "./components/Forms/Register/RegistrationSuccess"
import Dashboard from "./components/Dashboard/Dashboard"
import Unauthorized from "./components/Unauthorized/Unauthorized";
import Statistics from './components/Statistics/Statistics';


function ProtectedRoute(props){
  const [authorized, setAuthorized] = useState(null);
  useEffect(() => {
      checkAuthorization().then((response) => {
           if(!response) {
              setAuthorized(<Navigate to="/unauthorized" replace />);
          }
           else{
              setAuthorized(props.children ? props.children : <Outlet />);
           }
      });
  }, []
  );
  return(<div id="Authorized-Div">{authorized}</div>)
}
  


const Router = () => (
  <BrowserRouter>
    <Routes>
    <Route path="/unauthorized" element={<Unauthorized/>}></Route>
    <Route element={<ProtectedRoute/>}>
        <Route path="/admin/dashboard" element={<Dashboard/>}></Route>
        <Route path="/admin/statistics/*" element={<Statistics/>}></Route>
    </Route>
    {/* <Route path="/admin/registrationSuccess" element={<RegSuccess/>}></Route> */}
    <Route path="/login" element={<LoginForm/>}></Route>
    {/* <Route path="/admin/register" element={<RegisterForm/>}></Route> */}
    <Route path="/*" element={<URLComponent location={window.location}/>}></Route>
    </Routes>
  </BrowserRouter>
)

export default Router