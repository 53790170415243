import "./Login.css";
import { useState } from 'react';
import {TextField} from '@mui/material';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function RegisterForm(){
    return(
        <div id="loginForm">
          <FormHeader title="Login" />
          <Form name="loginForm"/>
        </div>
      )
}
  
  const FormHeader = props => (
      <h2 id="headerTitle">{props.title}</h2>
  );
  
 
  export function PasswordFieldWithStrength(props) 
  {
    if(props != null)
    {

    var password = "";
    if(props.inputs[props.name] != null)
    {
      password = props.inputs[props.name];
    }

    const [showPassword, setShowPassword] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const handleClickShowPassword = () => setShowPassword((show) => !show);



      return (
        <div class="row">
   
        <TextField required name={props.name}  onChange={props.handleChange} value={props.inputs[props.name]}  label="Password" type={showPassword ? "text" : "password"} placeholder="Password"
            InputProps={{endAdornment:
              <InputAdornment position="end">
                <IconButton id="passBtn"
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            
          }
        }
          />
      </div>
      );

  }
}
  
  async function registerAPI(formData){
    var valid = false;
    const options = {
    method: 'POST',
    data: {
      "username": formData["username"],
      "password": formData["password"],
    },
    url: window.location.origin+'/api/login',
    headers: {
        'content-type': 'application/json',
    },
};
    var final_response = null
    await axios(options).then((response) => {
      final_response = response;
    })
    return final_response
}
  

  function Form( props ){
    const [inputs, setInputs] = useState({});
    const navigate = useNavigate(); 
    const handleInputChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}))
    }

    function handleSubmit(event){
      event.preventDefault();
      var formData = {
        "username": inputs["username"],
        "password": inputs["password"],
      }
      registerAPI(formData).then((response) => {
        if(response.status == 201 || response.status == 200){
          localStorage.setItem("user", JSON.stringify(response.data));
          navigate("/admin/dashboard");
        }
      });
  }
  

  return(
        <form onSubmit={handleSubmit} class="row">
       <FormInput handleChange={handleInputChange} inputs={inputs} name="username" label="Username" placeholder="Enter your username" type="text" />
       <PasswordFieldWithStrength name="password" inputs={inputs} handleChange={handleInputChange}/>
       <FormButton handleChange={handleSubmit} title="Login"/>
       </form>
    );
  }



      
  
  const FormButton = props => (
    <div id="button" class="row">
      <button type="submit"> {props.title}</button>
    </div>
  );
  
  const FormInput = props => (
      <div class="row">
      <TextField required name={props.name} onChange={props.handleChange} value={props.inputs[props.name]} label={props.label} type={props.type} placeholder={props.placeholder}/>
      </div>
  );
  
  