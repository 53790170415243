import axios from "axios";


export async function getURLs(){
    const options = {
    method: 'GET',
    url: window.location.origin+'/api/url_all',
    headers: {
        'content-type': 'application/json',
    },
};
    var final_response = null
    await axios(options).then((response) => {
      final_response = response.data.urls;
    })
    return final_response
}
  
