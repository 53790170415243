import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Slider } from 'primereact/slider';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { getURLs } from '../../controllers/URLDataProvider';
import { updateExistingURL, removeExistingURLs } from '../../controllers/URLDataUpdater';
import { getCSRFToken } from "../../controllers/CRSFTokenProvider";
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';
import "primereact/resources/themes/soho-dark/theme.css";     
import 'primeicons/primeicons.css';

//core
import "./Dashboard.css"
import "primereact/resources/primereact.min.css";                                       
         


  export default function Dashboard()
  {
    let emptyURL = {
      key: "",
      short_url: '',
      long_url: "",
      qr_code: '',
      clicked: 0,
      date_created: 0,
  };
    const [urls, setURLs] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [selectedURLs, setSelectedURLs] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [url, setURL] = useState(emptyURL);
    const [URLDialog, setURLDialog] = useState(false);
    const [deleteURLDialog, setDeleteURLDialog] = useState(false);
    const [deleteURLsDialog, setDeleteURLsDialog] = useState(false);


    const toast = useRef(null);
    const dt = useRef(null);


    const openNew = () => {
      setURL(emptyURL);
      setSubmitted(false);
      setURLDialog(true);
  };

  const hideDialog = () => {
      setSubmitted(false);
      setURLDialog(false);
  };

  const hideDeleteURLDialog = () => {
      setDeleteURLDialog(false);
  };

  const hideDeleteURLsDialog = () => {
      setDeleteURLsDialog(false);
  };

  const saveURL = () => {
      setSubmitted(true);
      if (url.key.trim()) {

            setLoading(true);
            updateExistingURL({
              "short_url":url.key,
              "long_url": url.long_url
            }).then((response) =>{
              if(response.data.data.status == "created")
              {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Shortened URL Created', life: 3000 });
                setURLDialog(false);
                setURL(emptyURL);
                getURLs().then((urls) =>{
                  setURLs(formatDates(urls[0]));
                  setLoading(false);
                });
              }
              else{
                toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Shortened URL Creation Failed: '+response.data.data.error_message, life: 3000 });
                setURLDialog(false);
                setURL(emptyURL);
                getURLs().then((urls) =>{
                  setURLs(formatDates(urls[0]));
                  setLoading(false);
                });
              }
            });
          }
      };

  const editURL = (url) => {
      setURL({ ...url });
      setURLDialog(true);
  };

  const confirmDeleteURL = (url) => {
      setURL(url);
      setDeleteURLDialog(true);
  };

    

  const deleteURL = () => {

    removeExistingURLs([URL]);
      setDeleteURLDialog(false);
      setURL(emptyURL);
      toast.current.show({ severity: 'success', summary: 'Successful', detail: 'URL Deleted', life: 3000 });
  };

  const confirmDeleteSelected = () => {
      setDeleteURLsDialog(true);
  };

  const deleteSelectedURLs = () => {
        setLoading(true);

        removeExistingURLs(selectedURLs).then((response) =>{
            if(response.data.data.status == "deleted"){
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'All URLs Deleted', life: 3000 });
                setDeleteURLsDialog(false);
                setSelectedURLs(null);
                getURLs().then((urls) =>{
                  setURLs(formatDates(urls[0]));
                  setLoading(false);
                });
            }
            else if(response.data.data.status == "partial-success"){
                toast.current.show({ severity: 'warning', summary: 'Partially Successful', detail: 'Some URLS deleted', life: 3000 });
                setDeleteURLsDialog(false);
                setSelectedURLs(null);
                getURLs().then((urls) =>{
                  setURLs(formatDates(urls[0]));
                  setLoading(false);
                });
            }
            else{
                toast.current.show({ severity: 'error', summary: 'Unsuccessful', detail: 'Some error', life: 3000 });
                setDeleteURLsDialog(false);
                getURLs().then((urls) =>{
                  setURLs(formatDates(urls[0]));
                  setLoading(false);
                });
            }
          });
  };

  const onInputChange = (e, name) => {
      const val = (e.target && e.target.value) || '';

      let _url = { ...url };

      _url[`${name}`] = val;

      setURL(_url);
  };
  
  const formatDates = (data) => {
    return [...(data || [])].map((d) => {
        d.date_created = new Date(d.date_created);

        return d;
    });
};

const refreshURLs = () => {
    setLoading(true);
    getURLs().then((urls) =>{
        setURLs(formatDates(urls[0]));
        setLoading(false);
      });
};

  const leftToolbarTemplate = () => {
      return (
          <div className="flex flex-wrap gap-2">
              <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} />
              <Button label="Delete" icon="pi pi-trash" severity="danger" onClick={confirmDeleteSelected} disabled={!selectedURLs || !selectedURLs.length} />
          </div>
      );
  };
  const rightToolbarTemplate = () => {
    return (
        <div className="flex flex-wrap gap-2">
              <Button icon="pi pi-refresh" rounded raised onClick={refreshURLs} />
        </div>
    );
};


  const imageBodyTemplate = (rowData) => {
    return <img src={rowData.qr_code} alt="QR code for short link" className="shadow-2 border-round" style={{ width: '64px' }} />;
  };

const URLDialogFooter = (
    <React.Fragment>
        <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
        <Button label="Save" icon="pi pi-check" onClick={saveURL} />
    </React.Fragment>
);
const deleteURLDialogFooter = (
    <React.Fragment>
        <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteURLDialog} />
        <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteURL} />
    </React.Fragment>
);
const deleteURLsDialogFooter = (
    <React.Fragment>
        <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteURLsDialog} />
        <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedURLs} />
    </React.Fragment>
);

    const onRowEditComplete = (e) => {
      
      updateExistingURL({
        "old_slug":e.data.key,
        "short_url":e.newData.key,
        "long_url":e.newData.long_url
      }).then(response =>{
        if(response.data.status == "created" || response.data.status == "replaced")
        {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Shortened URL'+response.data.status, life: 3000 });
        }
        setLoading(true);
        getURLs().then((urls) =>{
     
     setURLs(formatDates(urls[0]));
     setLoading(false);
   });
      });

      
  };

    useEffect(() => {
      setURL(emptyURL);
      setLoading(true);
           getURLs().then((urls) =>{
        setURLs(formatDates(urls[0]));
        setLoading(false);
      });
        initFilters();
    }, []);


    const textEditor = (options) => {
      return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  };

  const formatDate = (value) => {
          return value.toLocaleString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        });
        
    };


    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);

    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            key: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            long_url: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            date_created: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },

        });
        setGlobalFilterValue('');
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };


    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData["date_created"]);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) =>{
            options.filterCallback(e.value, options.index)}
        }  dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };


    const header = renderHeader();

    return (
      <div class="dashboard">
        <Toast ref={toast} />
        <div className="card">
        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            <DataTable ref={dt} removableSort sortField='date_created'sortOrder={-1} scrollable scrollHeight="60vh" resizableColumns value={urls} paginator editMode="row" onRowEditComplete={onRowEditComplete} 
            selection={selectedURLs} stripedRows onSelectionChange={(e) => setSelectedURLs(e.value)} showGridlines rows={10} loading={loading} dataKey="key" 
                    filters={filters} globalFilterFields={['key', 'long_url', 'date_created']} header={header}
                    emptyMessage="No shortened URLs found.">
                <Column selectionMode="multiple" exportable={false}></Column>
                <Column field="key" editor={(options) => textEditor(options)} sortable header="Short URL Slug" filter filterPlaceholder="Search by slug" style={{ minWidth: '12rem' }} />
                <Column field="long_url" editor={(options) => textEditor(options)} sortable header="Long URL" filter filterPlaceholder="Search by long url" style={{ minWidth: '12rem' }} />
                <Column field="qr_code" sortable header="Short URL QR Code" body={imageBodyTemplate} style={{ minWidth: '12rem' }} />
                <Column field="clicked" header="Number of Clicks" sortable dataType="numeric" style={{ minWidth: '10rem' }} />
                <Column field="date_created" filterField="date_created" header="Date Created" sortable dataType="date" style={{ minWidth: '10rem' }} body={dateBodyTemplate} filter filterElement={dateFilterTemplate}  />
                <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
            </DataTable>
        </div>
        <Dialog visible={URLDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="URL Details" modal className="p-fluid" footer={URLDialogFooter} onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="slug" className="font-bold">
                        Short URL Slug. This is the part after mddsa.org/[slug]
                    </label>
                    <InputText id="slug" value={url.key} onChange={(e) => onInputChange(e, 'key')} required autoFocus className={classNames({ 'p-invalid': submitted && !url.key })} />
                    {submitted && !url.key && <small className="p-error">Short url slug is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="long_url" className="font-bold">
                        Long URL. The link you are wanting to redirect to
                    </label>
                    <InputText id="long_url" value={URL.long_url} onChange={(e) => onInputChange(e, 'long_url')} required autoFocus className={classNames({ 'p-invalid': submitted && !url.long_url })} />
                    {submitted && !url.long_url && <small className="p-error">Long URL is required.</small>}
                </div>
            </Dialog>

            <Dialog visible={deleteURLDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteURLDialogFooter} onHide={hideDeleteURLDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {url && (
                        <span>
                            Are you sure you want to delete <b>{URL.short_url}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteURLsDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteURLsDialogFooter} onHide={hideDeleteURLsDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {url && <span>Are you sure you want to delete the selected URLs?</span>}
                </div>
            </Dialog>
    </div>
    );
}