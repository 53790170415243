import React, { Component } from 'react';
import axios from "axios";
import { useEffect, useState } from 'react';
import { HalfMalf } from 'react-spinner-animated';
import 'react-spinner-animated/dist/index.css'
import "./QRComponent.css"

async function checkQRURL(short_url_check){
    var valid = false;
    const options = {
    method: 'GET',
    url: window.location.origin+'/api/'+short_url_check,
    headers: {
        'content-type': 'application/json',
    },
};
    console.log(options);
    let final_response = null;

    await axios(options).then((response) => {
        final_response = response;
    })
    return final_response;
}

export default function QRComponent(props){
    const [qrcode, setQRCode] = useState("");
    useEffect(() => {
        let url = props.location;
        console.log("QRComponent");
        checkQRURL(url).then(response => {
            console.log(response.data);
            let src_string = "" + response.data;
            setQRCode(src_string);
        });    
    }, []);
    if(qrcode == "")
    {
        return(<HalfMalf text={"Fetching QRCode..."}></HalfMalf>)
    }
    else{
        return(<img class="qr" src={qrcode}/>);
    }
}