import axios from "axios";
import { getCSRFToken } from "./CRSFTokenProvider";

export async function checkAuthorization(){

    var authorized = false;
    await axios.get(window.location.origin+'/api/authorization').then((response) => {
        // Success
        authorized = true;

    })
    .catch((error) => {
    authorized = false;
    
});
return authorized;
 };