import React, { Component } from 'react';
import { useEffect, useState } from 'react';

import "./Unauthorized.css"
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom'




function LoginRedirect( props ){
  let navigate = useNavigate(); 
  function handleClick(){
    console.log("Click");
      navigate("/login");
  }

return(
      <Button style={{display: "block", margin: "auto", marginTop: "6vh"}}onClick={handleClick} title="Login">Login</Button>
  );
}

export default function UnauthorizedPage(props){
    const [final_str, setStr] = useState("");

    var s = "--ERROR: 401_[UNAUTHORIZED]-- "; 
    var s1 = "-INVALID CREDENTIALS ENTERED-";
    var t = 0;
    var o = 0.5;
    var new_str = ""

    const shuffle = (str) => {
        var caps = str.toUpperCase();
        var char = "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var calc;

            for(var n = 0; n < caps.length; n++){
                calc = Math.floor(Math.random()*char.length);
                if(n == 0){
                    new_str = (t >= 0) ? caps.charAt(0) : char.charAt(calc); 
                    setStr(new_str);
                }
                if(n >= 1)
                {
                    var temp_str = (t >= 0 + n * 2) ? caps.charAt(n) : char.charAt(calc);
                    var new_str = new_str.concat(temp_str);
                    setStr(new_str);
                }
            }
        t++;
    };

    useEffect(() => {
      const interval = setInterval(() => {
        shuffle(s + s1);
      }, 50);

      return () => clearInterval(interval);

    }, []);


    
    return(
        <div>          
          <section class='err'>
            <div id="str">{final_str}</div>
            <LoginRedirect id="login-button"></LoginRedirect>          

          </section> 

          </div>);
        
}