import axios from "axios";
import { useEffect, useState } from 'react';


async function checkShortURL(short_url_check){

    var valid = false;
const options = {
    method: 'GET',
    url: window.location.origin+'/api/url',
    headers: {
        'content-type': 'application/json',
    },
    params: 
        {
            short_url: short_url_check,
        },
};

    let final_response = {};

    await axios(options).then((response) => {
        if(response.data.status == "does not exist")
        {
            final_response = {"status":false,"long_url":response.data.default_url};
        }
        else{
            
            final_response = {"status":true,"long_url":response.data.long_url,"short_url":response.data.short_url};
        }
    })
    return final_response;
}

export default function RedirectComponent(props){

    const [shorturl, setShortURL] = useState("");
    useEffect(() => {
        let url = props.location;
        checkShortURL(url).then((redirect) => {
             if (redirect.status) {
                var long_url_redirect = redirect.long_url;
                window.location.replace(redirect.long_url);
                return null;
            }
             else{
                console.log("Does not exist");
                var long_url_redirect = redirect.long_url;
                window.location.replace(redirect.long_url);
                return null;
             }
        });

        
        
    }, []);
    
}