import axios from "axios";





export async function updateExistingURL(formData){
    const options = {
    method: 'PUT',
    url: window.location.origin+'/api/url',
    headers: {
        'content-type': 'application/json',
    },
    data: {
        "short_url": formData["short_url"],
        "long_url": formData["long_url"],
        "old_slug": formData["old_slug"],
      },
};
    var final_response = null
    await axios(options).then((response) => {
      final_response = response;
    })
    return final_response
}
  
export async function removeExistingURLs(formData){
  const options = {
  method: 'DELETE',
  url: window.location.origin+'/api/url',
  headers: {
      'content-type': 'application/json',
  },
  data:{
    "urls":formData
  }
};
  var final_response = null
  await axios(options).then((response) => {
    final_response = response;
  })
  return final_response
}

