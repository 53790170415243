import React from 'react';
import QRComponent from './QRComponent/QRComponent';
import RedirectComponent from './RedirectComponent';

function isQR(path){
    const QRcodeurl = new RegExp('[A-Za-z0-9]+\.qr+');
    return QRcodeurl.test(path)
}

export default function URLComponent(props){
    console.log(props.location.pathname);
    let url = (props.location.pathname == "/"? "/": props.location.pathname.split("/")[1]);
    if(isQR(url))
    {
        return (<QRComponent location={url}></QRComponent>);
    }
    else{
        return(<RedirectComponent location={url}></RedirectComponent>);
    }
}